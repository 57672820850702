
<template>
<div class="copyright-container">
    
    <a class="container" v-if="false">
        <svg height="100%" width="100%" xmlns="http://www.w3.org/2000/svg">
            <rect class="outline" height="100%" width="100%" />
        </svg>
        <div class="text">联系我们</div>
    </a>

    <div class="contact">
      <div class="left">
        <img src="@/assets/yyun.png"/>
      </div>
      <div class="right">
        <span class="phone">电话: 13216111000(业务) &nbsp; 13567111314(技术)  </span>
        <br/>
        <span> 邮箱: sandy321@foxmail.com </span>
      </div>
    </div>

    <div class="copyrights">
      <div>
        Copyright &copy; 2023 YYUN 有瓜网络科技 版权所有 
      </div>
      <div>
        <a href="http://beian.miit.gov.cn?site=brandsite&amp;from=footer" target="_blank" data-ga-category="pc-footer" data-ga-action="click" data-ga-label="other-link-record" class="ga-data">浙ICP备19042613号 </a>
      </div>
    </div>
</div>
</template>

<script>

export default {
  components: { },
  name: 'Copyright',
  props: {
   
  }
}
</script>

<style lang="scss" scoped>


.copyright-container{
    position: relative;
    width: 100%;
   
    margin-top: 200px;
    margin-bottom: 10px;
    // background: #F1F1F1;

    .contact {
        width: 400px;
        margin: 10px auto;
        font-size: 12px;

        display: flex;

        .left{
          box-sizing: border-box;
          width: 46px;
          height: 46px;
          border:1px solid orangered;

          img {
            margin: 4px;
            width: 36px;
          }
        }
        .right{
          padding: 5px;
          text-align: left;
          .phone {
            margin-right: 20px;
          }
        }

    }

    .copyrights {
        width: 400px;
        margin: 0px auto;



        color: GRAY;
        text-align: center;

        display: flex;
        justify-content: space-between;

        a {
            color: GRAY;
            text-decoration: none;
        }
    }
}

</style>
<style lang="scss" scoped>
        
    
    // Position Mixin
    // Original: http://hugogiraudel.com/2013/08/05/offsets-sass-mixin/
    // Modified: https://codepen.io/piouPiouM/pen/bLmkz
    // ------------------------------------------------------
    $top-default:    null !default;
    $right-default:  null !default;
    $bottom-default: null !default;
    $left-default:   null !default;
    
    
    // Cover everything
    // ----------------
    @mixin coverer {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    @mixin position($type, $top: $top-default, $right: $right-default, $bottom: $bottom-default, $left: $left-default) {
    position: $type;
    
    $allowed_types: 'absolute' 'relative' 'fixed';
    @if not index($allowed_types, $type) {
    @warn "Unknown position: #{$type}.";
    }
    @each $data in top $top, right $right, bottom $bottom, left $left {
    #{nth($data, 1)}: nth($data, 2);
    }
    }
    @mixin absolute($top: $top-default, $right: $right-default, $bottom: $bottom-default, $left: $left-default) {
    @include position(absolute, $top, $right, $bottom, $left);
    }
    @mixin relative($top: $top-default, $right: $right-default, $bottom: $bottom-default, $left: $left-default) {
    @include position(relative, $top, $right, $bottom, $left);
    }
    @mixin fixed($top: $top-default, $right: $right-default, $bottom: $bottom-default, $left: $left-default) {
    @include position(fixed, $top, $right, $bottom, $left);
    }
    
    
    // Box sizing
    // ----------
    @mixin box-sizing($boxmodel) {
     -webkit-box-sizing: $boxmodel;
    -moz-box-sizing: $boxmodel;
     box-sizing: $boxmodel;
    }
    
    // Sizing
    // ------
    @mixin size($width, $height: $width) {
     width: $width;
     height: $height;
    }
    @mixin square($size) {
     @include size($size, $size);
    }
    
    // --------------------------------------------------
    // ANIMATIONS
    // --------------------------------------------------
    @mixin animation($animation...) {
     -webkit-animation: $animation;
    }
    @mixin animation-name($animation-name) {
     -webkit-animation-name: $animation-name;
    
    }
    @mixin animation-delay($animation-delay) {
     -webkit-animation-delay: $animation-delay;
    
    }
    @mixin animation-direction($animation-direction) {
     -webkit-animation-direction: $animation-direction;
    
    }
    @mixin animation-duration($animation-duration) {
     -webkit-animation-duration: $animation-duration;
    -moz-animation-duration: $animation-duration;
    -o-animation-duration: $animation-duration;
     animation-duration: $animation-duration;
    }
    @mixin animation-fill-mode($animation-fill-mode) {
     -webkit-animation-fill-mode: $animation-fill-mode;
    -moz-animation-fill-mode: $animation-fill-mode;
    -o-animation-fill-mode: $animation-fill-mode;
     animation-fill-mode: $animation-fill-mode;
    }
    @mixin animation-iteration-count($animation-iteration-count) {
     -webkit-animation-iteration-count: $animation-iteration-count;
    -moz-animation-iteration-count: $animation-iteration-count;
    -o-animation-iteration-count: $animation-iteration-count;
     animation-iteration-count: $animation-iteration-count;
    }
    @mixin animation-play-state($animation-play-state) {
     -webkit-animation-play-state: $animation-play-state;
    -moz-animation-play-state: $animation-play-state;
    -o-animation-play-state: $animation-play-state;
     animation-play-state: $animation-play-state;
    }
    @mixin animation-timing-function($animation-timing-function) {
     -webkit-animation-timing-function: $animation-timing-function;
    -moz-animation-timing-function: $animation-timing-function;
    -o-animation-timing-function: $animation-timing-function;
     animation-timing-function: $animation-timing-function;
    }
    
    
    @mixin translateX($x) {
    @include transform-single(translateX, $x);
    }
    @mixin transform-single($property, $value) {
     -webkit-transform: #{$property}unquote("("#{$value}")");
    -moz-transform: #{$property}unquote("("#{$value}")");
     -ms-transform: #{$property}unquote("("#{$value}")");
    -o-transform: #{$property}unquote("("#{$value}")");
     transform: #{$property}unquote("("#{$value}")");
    }
    
    // --------------------------------------------------
    // TRANSITIONS
    // --------------------------------------------------
    @mixin transition($transition...) {
     -webkit-transition: $transition;
    -moz-transition: $transition;
    -o-transition: $transition;
     transition: $transition;
    }
    @mixin transition-delay($transition-delay) {
     -webkit-transition-delay: $transition-delay;
    -moz-transition-delay: $transition-delay;
    -o-transition-delay: $transition-delay;
     transition-delay: $transition-delay;
    }
    @mixin transition-duration($transition-duration) {
     -webkit-transition-duration: $transition-duration;
    -moz-transition-duration: $transition-duration;
    -o-transition-duration: $transition-duration;
     transition-duration: $transition-duration;
    }
    @mixin transition-property($transition-property) {
     -webkit-transition-property: $transition-property;
    -moz-transition-property: $transition-property;
    -o-transition-property: $transition-property;
     transition-property: $transition-property;
    }
    @mixin transition-timing-function($transition-timing-function) {
     -webkit-transition-timing-function: $transition-timing-function;
    -moz-transition-timing-function: $transition-timing-function;
    -o-transition-timing-function: $transition-timing-function;
     transition-timing-function: $transition-timing-function;
    }
    
    
    
    // --------------------------------------------------
    // KEYFRAMES
    // --------------------------------------------------
    @mixin keyframes($animationName) {
     @-webkit-keyframes #{$animationName} { @content; }
     @-moz-keyframes #{$animationName} { @content; }
     @-o-keyframes #{$animationName} { @content; }
     @keyframes #{$animationName} { @content; }
    }
    
    
    
    
    
    $default: #409eff;
    $pink: #68C359;
    
    html {
      background: #FFF;
      text-align: center;
      color: $default;
      *,
      *:before,
      *:after {
        @include transition(1s);
        @include box-sizing(border-box);
      }
    }
    
    [class^="container"] {
      display: block;
      position: relative;
      color: $default;
      text-decoration: none;
      @include size(250px, 50px);
      margin: 50px auto;
      overflow: hidden;

      cursor: pointer;
      .outline {
        @include coverer;
        stroke: $default;
        stroke-width: 2px;
        fill: transparent;
      }
      .text {
        @include relative(-40px);
        font-family: 'Helvetica';
        font-size: 1.5rem;
        line-height: 1;
        letter-spacing: 1px;
        text-transform: uppercase;
      }
      &:hover {
        .outline {
          stroke: $pink;
        }
        .text {
          color: $pink;
        }
      }
    }
    
    .container {
      .outline {
        stroke-dasharray: 25 25;
        stroke-dashoffset: -588;
      }
      &:hover {
        .outline {
          stroke-dasharray: 50 50;
          stroke-dashoffset: -275;
        }
      }
    }
    
    
    @include keyframes(woop) {
      0% {
        stroke-dasharray: 50 600;
        stroke-dashoffset: -550;
      }
      40%, 50% {
        stroke-dasharray: 50 600;
        stroke-dashoffset: -250;
      }
      100% {
        stroke-dasharray: 600 0;
        stroke-dashoffset: 25;
      }
    }
    
    
    
    @include keyframes(bounceRight) {
      0% {
        @include translateX(-100%);
      }
      30% {
        @include translateX(-100%);
      }
      40%, 60%, 80%, 100% {
        @include translateX(0);
      }
      50% {
        @include translateX(-30%);
      }
      70% {
        @include translateX(-15%);
      }
      90% {
        @include translateX(-7.5%);
      }
    }
    
    
    
      
    </style>