
<template>
<div class="cube-container">
    <div class="row">
        <div class="col">
            <div class="cube">
                <div class="square" v-for="square in  leftSquares" :key="square"  @mouseover="squareLeft = square.code;">
                    <div class="inner">
                        <div class="content" :class="squareLeft == square.code ? 'active-content':'' ">
                            <span>{{ square.label }}</span>
                        </div>
                        <div class="border left"></div>
                        <div class="border top"></div>
                        <div class="border right"></div>
                        <div class="border bottom"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col col-info">
            <div :class="squareLeft === 'ONLINE' ? 'show':''" >
                <!-- <div class="bg"> <img src='@/assets/bg/indexCfg.jpg' />  </div> -->
                <div class="title">在线商城</div>
                <div class="logo"><img src="@/assets/best1.png" /></div>
                <div class="content">
                    快速上线、扩展、优惠推广 
                </div>
            </div>
            <div :class="squareLeft === 'CUSTOMS' ? 'show':''" >
                <div class="title">跨境一步达</div>
                <div class="logo"><img src="@/assets/vasayo.png" /></div>
                <div class="content">
                    保税进口，三单合一，快速通关<br/>一般贸易，专业申请，快速通过!
                </div>
            </div>
            <div :class="squareLeft === 'ACCOUNTS' ? 'show':''" >
                <div class="title">财务优化</div>
                <div class="logo"></div>
                <div class="content">
                    财务合规，收款更轻松
                </div>
                
            </div>
            <div :class="squareLeft === 'OFFLINE' ? 'show':''">
                <div class="title">实体门店</div>
                <div class="logo">#万聪听力</div>
                <div class="content">
                    集库存管理、销售收款、利润计算、营业提成等于一体，<br/>解放老板生产力!
                </div>
            </div>
        </div>
    </div>

    <div class="row">

        <div class="col col-info">
            <div :class="squareRight === 'VIEWS' ? 'show':''"  >
                
                <div class="title">多界面覆盖</div>
                <div class="logo"></div>
                <div class="content">安卓、苹果、H5、小程序</div>
            </div>
            <div :class="squareRight === 'FAST' ? 'show':''" >
                <div class="title">快速扩展</div>
                <div class="logo"></div>
                <div class="content">
                   镜像化部署实施，安全稳定，快速上线，快速扩展部署
                </div>
            </div>
            <div :class="squareRight === 'STOCK' ? 'show':''" >
                <div class="title">库存ERP</div>
                <div class="logo"></div>
                <div class="content">
                    库存出入、调拨、盘点、出售...
                </div>
                
            </div>
            <div :class="squareRight === 'VISUAL' ? 'show':''">
                <div class="bg"> <img src='@/assets/bg/indexCfg.jpg' />  </div>
                <div class="title">所见即所得</div>
                <div class="logo"></div>
                <div class="content">
                   首页、活动页、发现页、商品页... <br/> 页页配置所见即所得，降低运营成本及风险
                </div>
            </div>
        </div>

        <div class="col">
            <div class="cube">

                <div class="square" v-for="square in  rightSquares" :key="square"  @mouseover="squareRight = square.code;">
                    <div class="inner">
                        <div class="content" :class="squareRight == square.code ? 'active-content':'' ">
                            <span>{{ square.label }}</span>
                        </div>
                        <div class="border left"></div>
                        <div class="border top"></div>
                        <div class="border right"></div>
                        <div class="border bottom"></div>
                    </div>
                </div>    

            </div>

        </div>
    </div>
</div>
</template>

<script>


export default {
  components: { },
  name: 'Cube',
  props: {},
  data() {
    return {
        squareLeft: 'ONLINE',
        squareRight: 'VIEWS',

        leftSquares:[
            {code: "ONLINE", label:"线上商城"},
            {code: "CUSTOMS", label:"跨境清关"},
            {code: "ACCOUNTS", label:"分销分账"},
            {code: "OFFLINE", label:"线下门店"},
        ],
        rightSquares:[
            {code: "VIEWS", label:"多端覆盖"},
            {code: "FAST", label:"极速扩展"},
            {code: "STOCK", label:"进销库存"},
            {code: "VISUAL", label:"可视配置"},
        ]
           
    };
  },
  methods:{
    hover(square){
        console.log(square)
        this.squareLeft = square;
    }
  }
}
</script>

<style lang="scss" scoped>
    @import url('@/resource/css/Cube.scss');
    .row {
        width: 800px;
        margin: 50px auto;
        
        display: flex;

        .col{
            flex: 1;
        }

        
        &:last-child {
            margin-top: 120px;

            .col:last-child{
                display: flex;
                justify-content: flex-end;
            }
        }

        .col-info{
            position: relative;
                     
            >div{
                position: absolute;
                top: 0px;
                left: 0px;
                width: 100%;
                height: 100%;

                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                

                opacity: 0;
                transition: opacity 1s ease-in-out;

                .title {
                    font-size: 30px;
                    line-height: 50px;
                }
                .logo {
                    width: 60px;
                    height: 60px;
                    img{
                        width: 60px;
                        height: 60px;
                    }
                }
                .content{
                    font-size: 14px;
                }
                
            }

            .show {
                opacity: 1;
            }

            .bg {
                position: absolute;
                top: -20px;
                left: -100px;
                width: 400px;
                height: 200px;
                // background: url('@/assets/bg/indexCfg.jpg');

                background-repeat: no-repeat;

                z-index: -1;
                opacity: .5;
                // transform: rotate(0deg);

                img{
                    width: 400px;
                    height: 230px;
                }
            }
            
        }

    }


</style>
