
<template>
<div class="ugua-container">

    <div class="names">
        <div class="ugua">杭州有瓜网络科技 <span style="font-size: 12px">优质电商平台支持者</span></div>
    </div>
   


   <div class="line-container">
        <div class="line-wrap">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" 
                class="circle-load-rect-svg" width="1000" height="670" viewbox="0 0 1000 670">

                <polyline points="995 5, 5 5, 5 330, 995 330, 995 660, 5 660" class="g-rect-path" />
                <polyline points="995 5, 5 5, 5 330, 995 330, 995 660, 930 660" class="g-rect-filled"   />
                <polyline points="930 660, 5 660" class="g-rect-fill"   />
                <text x="890" y="25" style="text-anchor:start; font-size: 14px;"><tspan font-weight="bold" fill="rgba(104,195,89,1)">2019 v1.0 开启</tspan></text>
                <text x="20" y="25" style="text-anchor:start; font-size: 14px;"><tspan font-weight="bold" fill="rgba(104,195,89,1)">2020 v2.0 好易购</tspan></text>
                <text x="20" y="320" style="text-anchor:start; font-size: 14px;"><tspan font-weight="bold" fill="rgba(104,195,89,1)">2021 v3.0 薇尚优</tspan></text>
                <text x="950" y="350" style="text-anchor:start; font-size: 14px;"><tspan font-weight="bold" fill="rgba(104,195,89,1)">2022</tspan></text>
                <text x="875" y="650" style="text-anchor:start; font-size: 14px;"><tspan font-weight="bold" fill="rgba(104,195,89,1)">2023 v4.0 光艺家</tspan></text>
                <text x="10" y="650" style="text-anchor:start; font-size: 14px;"><tspan font-weight="bold" fill="#409eff">2023 v4.1 ERP升级换新 </tspan></text>

            </svg>
        </div>
    </div>
 


    <div  class="main">
        <cube></cube>
    </div>

<ty-shops></ty-shops>
<partners></partners>
<copyright></copyright>
 
</div>
</template>

<script>

// import Circle from './Circle-2.vue'
import Demo from './Demo.vue'
import Partners from './Partners.vue'
import Copyright from './Copyright.vue'
import Cube from './Cube.vue'
import TyShops from './TyShops.vue'
export default {
  components: {  Demo, Partners, Copyright, Cube, TyShops  },
    name: 'HelloWorld',
  props: {
   
  }
}
</script>
<style scoped>
    @import url('@/resource/css/ugua.css');
    
</style>
<style lang="scss">
    
    .main{
        margin-top: 150px;
        height: 700px;
    }
    


    body{
        font-size:10px;
    }

    .line-container{
        position: absolute;
        width: 1000px;
        height: 700px;
        top: 110px;
        left: 0;
        right:0;
        margin: 0 auto;  
        
        --stroke-linecap:butt ;//round;

        .line-wrap{
            width:1000px;
            height: 700px;
            margin:0 auto;
        }
        .circle-load-rect-svg{
            margin: 10px;
        }

        .g-rect-path{
            fill: none;
            stroke-width:5;
            stroke:#d3dce6;
            stroke-linejoin: var(--stroke-linecap);
            stroke-linecap: var(--stroke-linecap);
        }

        .g-rect-filled{
            fill: none;
            stroke-width:5;
            //stroke:#ff7700;
            stroke: rgba(104,195,89,.5);
            stroke-linejoin:var(--stroke-linecap);;
            stroke-linecap:var(--stroke-linecap);;
            stroke-dasharray: 0, 13370;
            stroke-dashoffset: 0;
            animation: lineMoved 30s ease-out 1s forwards;
        }


        @-webkit-keyframes lineMoved {
            0%{
                stroke-dasharray: 0, 13350;
                stroke:rgba(104,195,89,.7);
            }
            100%{
                stroke-dasharray: 13350, 13350;
                stroke:rgba(104,195,89,1);
            }
        }

        .g-rect-fill{
            fill: none;
            stroke-width:5;
            //stroke:#ff7700;
            stroke:rgba(64,158,255, 1);
            stroke-linejoin:var(--stroke-linecap);;
            stroke-linecap:var(--stroke-linecap);;
            
            stroke-dasharray: 0, 13370;
            stroke-dashoffset: 0;
            animation: lineMove 30s ease-out 5s forwards;
            // animation-delay: 5s;
          
        }

        @-webkit-keyframes lineMove {
            0%{
                stroke-dasharray: 0, 13350;
                stroke:rgba(64,158,255, 1);
            }
            100%{
                stroke-dasharray: 13350, 13350;
                stroke:rgba(64,158,255, 1);
            }
        }

       
    }
    



</style>
