
<template>
<div class="partners-container">
    <hgroup class="svg-border-animation">
        <svg viewBox="0 0 320 60" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <rect class="shape" height="60" width="320"></rect>
        </svg>
        <div class="hover-text">合作伙伴</div>
    </hgroup>

    <div class="partners-list">
        <div class="item" v-for="partner in partners" :key="partner" @click="partner.link && link(partner.link)">
            <div class="logo">
                <div class="logo-section">
                <div class="title"><img :src="partner.logo" /></div>
                </div>
                <div class="scaner-section" >
                    <div class="scaner">
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="200px" height="200px">
                            <!-- <polygon class="g-polygon-wrap" points="150 0, 300 75, 300 225, 150 300, 0 225,  0 75, 150 0" /> -->
                            <polygon class="g-polygon-wrap" points="100 0, 200 50, 200 150, 100 200, 0 150,  0 50, 100 0" />
                            <polygon class="g-polygon-move" points="100 0, 200 50, 200 150, 100 200, 0 150,  0 50, 100 0" />
                            <!-- <polygon class="g-polygon-move" points="150 0, 300 75, 300 225, 150 300, 0 225,  0 75, 150 0" /> -->
                        </svg>
                    </div>
                    <div>
                        <img :src="partner.wxmpcode" />
                    </div>
                </div>
            </div>
            <div class="text">
                
                <div class="tags">&nbsp;&nbsp;&nbsp;&nbsp;
                    <template v-if="partner.tags">
                        <span v-for="tag in partner.tags" :key="tag">
                            {{tag}}
                        </span>
                    </template>
                    
                </div>
                <div>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ partner.text }}
                </div>
            </div>
           
        </div>
        <div class="item" v-if="false">
            <div class="logo-section">
                <div class="title"><img src="@/assets/vasayo.png" /></div>
            </div>
            <div class="scaner-section" @click="link('http://www.vasayo888.com')">
                <div class="scaner">
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="300px" height="300px">
                        <polygon class="g-polygon-wrap" points="150 0, 300 75, 300 225, 150 300, 0 225,  0 75, 150 0" />
                        <polygon class="g-polygon-move" points="150 0, 300 75, 300 225, 150 300, 0 225,  0 75, 150 0" />
                    </svg>
                </div>
                <div>
                    <img src="@/assets/lightWxmp.png" />
                </div>

            </div>
        </div>
       
    </div>
</div>
</template>

<script>

export default {
  components: { },
  name: 'Partners',
  props: {
   
  },
  data() {
    return {
        partners:[
            {logo:require('@/assets/best1.png'), 
                link:"http://www.zrtg.com/channel/6064.html?one=0&two=9", 
                wxmpcode:require('@/assets/best1Wxmp.png'),
                text:"好易购由浙江广电集团倾力打造，于2006年12月开播，是浙江省唯一一家专业电视购物频道，打造跨媒体、多平台、立体化的商业综合体。",
                tags:['广电','电视购物']
            },
            {logo:require('@/assets/vasayo.png'), link:"http://www.vasayo888.com",
                wxmpcode:require('@/assets/vasayoWxmp.png'),
                text:"薇尚优是一个扎根于大健康垂直领域的社交跨境电商服务平台，专注大健康、美丽和品质生活3大领域。",
                tags:['跨境','保税进口','一般贸易']
            },
            {logo:require('@/assets/yyun.png'), link:null,wxmpcode:require('@/assets/lightWxmp.png')},
            // {logo:require('@/assets/best1.png'), link:"http://www.zrtg.com/channel/6064.html?one=0&two=9", wxmpcode:require('@/assets/lightWxmp.png') },
            // {logo:require('@/assets/best1.png'), link:"http://www.zrtg.com/channel/6064.html?one=0&two=9", wxmpcode:require('@/assets/lightWxmp.png') },
        ]
    };
  },
  methods: {
    link(url){
        url &&
        window.open(url,'target');
    }
  }
}
</script>

<style lang="scss" scoped>

.partners-container {
    width: 1000px;
    margin: 100px auto;
    margin-top: 200px;
}

.partners-list {
    width: 1000px;
    margin: 20px auto;
    margin-top: 70px;
    
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;


    .item{
        
        width: 200px;
        height: 320px;
        padding: 10px;
        border: 1px solid #F9F9F9;
        // border: 1px solid #E9E9F9;

        cursor: pointer;

        overflow: hidden;

        
       .logo {
            width: 100%;
            height: 200px;
            display: flex;
            > div{
                display: flex;
                justify-content: center;
                align-items: center;
                transition: all 1s ease-in-out;
                overflow: hidden;
            }

            .logo-section {
                width: 100%;
            }
            .scaner-section {
                width: 0px;
                // opacity: 0;
                .scaner {
                    opacity: 0;
                }
            }
       }

       .text {
            width: 100%;
            height: 160px;
            margin-top: 10px;
            text-align: left;
            ;

            .tags {
                margin: 5px 0px;
                font-size: 14px;
                color: orangered;

                span{
                    display: inline-block;
                    margin-right: 10px;
                }
            }
       }

        

        

       
        
    }

    .item:hover {
        .logo-section {
            width: 0px;
        }
        .scaner-section {
            width: 100%;
            opacity: 1;

            .scaner {
                opacity: 1;
            }
        }
    }

    img {
        width: 120px;
        height: 120px;
    }

    
   

}


//扫码
.scaner {
    position: absolute;
    opacity: 0;
    transition: all .5s ease-in-out;
    svg {
        margin: auto;
        overflow: visible;
        transform: scalex(.9);
    }

    .g-polygon-wrap,
    .g-polygon-move {
        fill: none; 
        stroke: orangered; 
        stroke-width: 2;
        stroke-linejoin: round;
        stroke-linecap: round;
    }

    .g-polygon-move {
        transform-origin: center center;
        transform: scale(1.08);
        stroke: linear-gradinet(180deg, orangered, transprent);
        stroke-width:1.5;
        stroke-dasharray: 160, 500;
        stroke-dashoffset: 8;
        animation: move 3.0s infinite linear;
    }

    @keyframes move {
        0% {
            stroke-dashoffset: 8;
        }
        100% {
            stroke-dashoffset: -620;
        }
    }

}


//合作伙伴    
.svg-border-animation {
    width: 320px;
    height: 60px;
    margin: 0px auto;
    transform: translate(0%, -50%);
}

.hover-text {
    position: absolute;
    line-height: 60px;
    width: 320px;
    top: 0;
    // color: #333;
    color: orangered;
    font-size: 28px;
    text-align: center;
    cursor: pointer;
}

.shape {
    fill: transparent;
    stroke-width: 4px;
    // stroke: #333;
    stroke: orangered;
    stroke-dasharray: 160 520;
    stroke-dashoffset: -460;
}

.svg-border-animation:hover .hover-text {
    transition: 0.5s;
    // color: #3F51B5;
    color: orangered;
}

.svg-border-animation:hover .shape {
    -webkit-animation: draw 0.5s linear forwards;
    animation: draw 0.5s linear forwards;
}

@keyframes draw {
    0% {
        stroke-dasharray: 160 520;
        stroke-dashoffset: -460;
        stroke-width: 4px;
    }
    100% {
        stroke-dasharray: 760;
        stroke-dashoffset: 0;
        stroke-width: 2px;
        // stroke: #3F51B5;
        stroke: orangered;
    }
}




</style>
