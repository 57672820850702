
<template>
<div class="partners-container">
     

    <div class="container">
        <div class="store">
            <div class="roof"> </div>
            <div class="eave">
                <div v-for="i in 7" :key="i" class="item"></div>
            </div>
            <div class="body">
                <div class="lamp-pole"></div>
                <div class="lamp-sign">{{ shopName }}</div>
                <div class="door">
                    <div class="handle"></div>
                    <div class="keyhole"></div>
                </div>
                <div class="window">
                    <div class="frame"></div>
                    <div class="lamp"></div>
                </div>
            </div>
        </div>
    </div>

    <hgroup class="svg-border-animation">
        <svg viewBox="0 0 320 60" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <rect class="shape" height="60" width="320"></rect>
        </svg>
        <div class="hover-text" @click="toTyShops"> 通益千店 》</div>
        <div>永久免费线上、下门店销售库存平台</div>
    </hgroup>  
</div>
</template>

<script>

export default {
  components: { },
  name: 'TyShops',
  props: {
   
  },
  data() {
    return {
        shopName:"通益千店"
    };
  },
  methods: {
    toTyShops(){
        window.open("https://tyqd.yuyuun.com/",'target');
    }
  }
}
</script>

<style lang="scss" scoped>

.partners-container {
    position: relative;
    width: 1000px;
    margin: 10px auto;
}


@keyframes draw {
    0% {
        stroke-dasharray: 160 520;
        stroke-dashoffset: -460;
        stroke-width: 4px;
    }
    100% {
        stroke-dasharray: 760;
        stroke-dashoffset: 0;
        stroke-width: 2px;
        // stroke: #3F51B5;
        stroke: orange;
    }
}


//合作伙伴    
.svg-border-animation {
    position: absolute;
    width: 320px;
    height: 60px;
    right: 100px;
    top: 60%;
    transform: translate(0%, -50%);
}

.hover-text {
    position: absolute;
    line-height: 60px;
    width: 320px;
    top: 0;
    // color: #333;
    color: orange;
    font-size: 28px;
    text-align: center;
    cursor: pointer;
}

.shape {
    fill: transparent;
    stroke-width: 4px;
    // stroke: #333;
    stroke: orange;
    stroke-dasharray: 160 520;
    stroke-dashoffset: -460;
}

.svg-border-animation:hover .hover-text {
    transition: 0.5s;
    // color: #3F51B5;
    color: orange;
}

.svg-border-animation:hover .shape {
    -webkit-animation: draw 0.5s linear forwards;
    animation: draw 0.5s linear forwards;
}



   
.container {

--bgcolor:#F9F9F9;
--color1: #D9D9D9;
--color-light: orange;

position: relative;
height: 500px;
width: 1000px;
// background: var(--bgcolor);
overflow: hidden;



@keyframes lamp-shadow {
  0% { box-shadow: 0px 0px 3px var(--color-light); }
  20% { box-shadow: 0px 0px 5px var(--color-light); }
  40% { box-shadow: 0px 0px 10px var(--color-light); }
  60% { box-shadow: 0px 0px 20px var(--color-light); }
  80% { box-shadow: 0px 0px 10px var(--color-light); }
  100% { box-shadow: 0px 0px 5px var(--color-light); }
}





.store {
    position: absolute;
    top: 30%;
    left: 20%;
    width: 280px;
    height: 1000px;
    .roof{
   
        margin: auto;
        width: 208px;
        height: 75px;
        background-color: var(--color1);
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        transform: perspective(20px) rotateX(8deg) rotateY(0deg) translateZ(0);
    }

    .eave {

        margin-top: 20px;
        margin-left: 0px;
        width: 280px;
        display: flex;

        .item {
          flex: 1;
          margin-right: 5px;
          width: 40px;
          height: 20px;
          border-radius:0 0 20px 20px ;
          line-height: 20px;
          background: var(--color1);
        }
        .item:last-child{
            margin-right: 0px;
        }

    }

    .body {
        position: relative;
        box-sizing: border-box;
        margin-top: 5px;
        margin-left: 12px;
        width: 255px;
              height: 150px;



        border: 10px solid var(--color1);
        border-top: none;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;

        .lamp-pole {
          position: absolute;
          top: 10px;
          left: -15px;
          width: 5px;
          height: 20px;
          border: 2px solid var(--color1);
          border-left: none;
        }
        .lamp-sign {
          position: absolute;
          left: -35px;
          padding: 3px 0px;
          writing-mode:vertical-lr;
          background: rgba(255,165,0, 0.7);
          color: #FFF;
          border-radius: 4px;
          border:1px solid #FFF;
          box-shadow: 0px 0px 5px var(--color-light);
          animation: lamp-shadow 2s linear infinite;
        }

        
        .door {
            position: absolute;
            left: 20px;
            bottom: -10px;
            width: 50px;
            height: 120px;
            border: 5px solid var(--color1);
            border-bottom: none;
            border-radius: 10px;

            .handle {
                position: absolute;
                left: 30px;
                top: 50px;
                width: 10px;
                height: 10px;
                border: 2px solid var(--color1);;
                border-radius: 10px;

            }
            .keyhole{

                position: absolute;
                left: 35px;
                top: 65px;
                width: 4px;
                height: 4px;
                background: var(--color-light);
                border-radius: 10px;

            }
        }

        .window {
            position: absolute;
            right: 18px;
            bottom: 20px;
            width: 110px;
            height: 80px;
            border: 5px solid var(--color1);
            background: rgba(255,165,0, .1);
            border-radius: 10px;
            box-shadow: inset 0px 0px 16px 0px rgba(255,165,0, .5);

            .frame {
              box-sizing: border-box;
              position: absolute;
              width: 100%;
              height: 100%;
              border: 1px solid #FFF;
              border-radius: 5px;
            }
            
            .lamp{
                position: absolute;
                left: 50px;
                margin-top: 14px;
                width: 10px;
                height: 10px;
                background: var(--color-light);
                border: 1px solid #FFF;
                border-radius: 10px;
                animation: lamp-shadow 3s linear infinite;
            }

            .lighting{                    
                margin-left: 55px;
                margin-top: 20px;
                width: 700px;
                height: 700px;
                background: var(--color-light);
                opacity: .5;
                border-color: orange(248,141,30, .6);
                

                -webkit-transform-origin: left top;
                transform-origin: left top;

                transform: skew(20deg);
                rotate: -35deg;   
                animation: lamp-shadow 3s linear infinite;
            }
        }
    }
}

.login {
    position: absolute;
    top: 0px;
    left: 45%;
    width: calc(100% - 45%);
    height: 100%;
    background:  var(--bgcolor);
    .form {
        position: absolute;
        box-sizing: border-box;
        top: 20%;
        left: 0px;
        width: 600px;
        height: 500px;
        padding: 50px;
        background: #FFF;

        border-radius: 20px;
        // box-shadow: 0px 0px 12px rgba(0, 0, 0, .12);
        // box-shadow: 0px 0px 12px var(--color-light);
        animation: lamp-shadow 3s linear infinite;


       
        
    }

}

}




</style>
