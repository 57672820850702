<template>
  <!-- <HelloWorld /> -->
  <not-found />
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'
import NotFound from './components/NotFound.vue';
export default {
  name: 'App',
  components: {
    HelloWorld, NotFound
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

body{
  margin: 0px;
  color: #606266;
}
</style>
