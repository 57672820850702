
<template>
<div class="ugua-container">

    <div class="not-found">404</div>

    <copyright></copyright>
 
</div>
</template>

<script>


export default {
  components: {    },
    name: 'HelloWorld',
  props: {
   
  }
}
</script>
<style lang="scss" scoped>
    .not-found {
        margin: 100px;
        font-size: 50px;
    }

</style>
<style lang="scss">
    
   



</style>
